:root {
  --amplify-primary-color: #ffc107;
  --amplify-primary-tint: #f7e9af;
  --amplify-primary-shade: #f8f9fa;
  --amplify-background-color: rgba(135, 115, 83, 0.877);
  --header-color: red;
}


.navbar-nav .active > .nav-link,
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show,
.navbar-nav .show > .nav-link {
  color: #fff;
  padding-bottom: 1rem;
  margin-bottom: 0;
  border-bottom: 2px solid red;
}
.navbar-nav .nav-link {
  color: red !important;
}

.loginPage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size:auto;
    background-color: black;
}
.loginFormContainer{
  display: flex;
    flex-direction: column;
    width: 40%;
    align-items: center;
}

.amplify-sign-in{
  --header-color: red
}


element.style {
    color: red;
}

.machine-state {
  width: 15px;
  height: 15px;
  border-radius: 50%;

  &-1 {
    background-color: red;
  }
  &-2 {
    background-color: orange;
  }
  &-3 {
    background-color: green;
  }
  &-4 {
    background-color: orange;
  }
  &-offline {
    background-color: grey;
  }
}

.cursor-pointer {
  cursor: pointer;
}
.only-print {
  display: none;
}
.error {
  color: red;
  font-size: small;
  padding-left: 1em;
}


@media print {
  .no-print {
    display: none;
  }
  .only-print {
    display: block;
  }
}

@import "bootstrap/scss/bootstrap.scss";
